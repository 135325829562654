import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-2" }
const _hoisted_3 = { class: "text-gray-600" }
const _hoisted_4 = {
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_5 = {
  key: 0,
  scope: "col",
  nowrap: "",
  colspan: "2",
  class: "text-center p-2"
}
const _hoisted_6 = {
  key: 1,
  scope: "col",
  nowrap: "",
  colspan: "2",
  class: "text-center p-2"
}
const _hoisted_7 = {
  class: "text-gray-600",
  style: {"background-color":"#fff"}
}
const _hoisted_8 = {
  scope: "col",
  class: "p-2",
  nowrap: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_ctx.invoice.is_amend != 0 || _ctx.invoice.amend_type == 3)
    ? (_openBlock(), _createBlock(_component_el_popover, {
        key: 0,
        placement: "right",
        width: "180",
        trigger: "hover"
      }, {
        reference: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass({'in-active-policy': _ctx.invoice?.is_active == 0}),
            style: {"cursor":"pointer"}
          }, _toDisplayString(_ctx.invoice.merchant_reference), 3)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("table", _hoisted_3, [
                _createElementVNode("tbody", null, [
                  _createElementVNode("tr", _hoisted_4, [
                    (_ctx.invoice.amend_type != 3)
                      ? (_openBlock(), _createElementBlock("th", _hoisted_5, _toDisplayString(_ctx.invoice.is_amend == 1 ? 'Parent' : '') + " Quote Ref. No. ", 1))
                      : _createCommentVNode("", true),
                    (_ctx.invoice.amend_type == 3)
                      ? (_openBlock(), _createElementBlock("th", _hoisted_6, _toDisplayString(_ctx.invoice.amend_type == 3 ? 'Original' : '') + " Quote Ref. No. ", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("tr", _hoisted_7, [
                    _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.invoice.invoice_number), 1)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("span", {
        key: 1,
        class: _normalizeClass({'in-active-policy': _ctx.invoice?.is_active == 0})
      }, _toDisplayString(_ctx.invoice.merchant_reference), 3))
}