
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "show-quote-ref-no",
  props: {
    invoice: Object,
  },
  setup() {
    const store = useStore();
    let invoiceId = ref<string>("");

    return {
      invoiceId
    };
  },
});
